<template>
  <section id="panoramaW" class="panorama-info p-0" :class="isFullScreen">

    <div class="controls">
      <div class="fs-button" @click="toggleFullScreen()">
        <d-icon :icon="fullScreenIcon" size="2xs" />
      </div>
      <div class="pie">
        <div :style="pieSector"></div>
      </div>
   </div>

    <div v-if="info.content !== 'video'" id="panoramaWrapper"></div>

    <div v-else id="panoramaWrapper" class="view360-container">
      <canvas class="view360-canvas"></canvas>
      <video id="panoVideo"
        :src="info.video.url"
        playsInline
        crossOrigin="anonymous"
        loop></video>
    </div>

    <div v-if="!sensor" class="poster">
      <div class="gyro-button" @click="enableSensor()">
        <d-icon
          icon="FaReg360Degrees"
          size="is-medium"/>
       </div>
    </div>

    <div
      class="link-buttons columns is-mobile"
      v-if="info.buttons.length && !fullscreen"
    >
      <div
        v-for="(button, index) in info.buttons"
        class="column"
        :class="buttonClass(index)"
        :key="button.id"
      >
        <a
          :href="GetFullUrl(button.link)"
          class="button is-fullwidth is-primary is-outlined"
          target="_blank"
        >
          <d-icon
            v-if="button.icon"
            :icon="button.icon"
            size="is-small"
            class="mr-1"
          />
          {{ button.label }}
        </a>
      </div>
    </div>
  
  </section>

</template>

<script>
import './PanoramaInfo.scss'

import { PanoViewer } from "@egjs/view360"

export default {
  components: {  },
  props: {
    info: {
      type: Object,
      required: true,
    },
    isOnComputer: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      player: null,
      panoViewer: undefined,
      sensor: false,
      vr: false,
      mode: 'none',
      projectionType: 'equirectangular',
      fullscreen: false,
      view: {
        yaw: 0
      }
    }
  },
  computed: {
      panWrapper() {
        return document.getElementById("panoramaWrapper")
      },
      panW() {
        return document.getElementById("panoramaW")
      },
      isFullScreen(){
        return this.fullscreen ? 'fullscreen' : null
      },
      initSize(){
        return {
          width: this.panWrapper.clientWidth,
          height: this.panWrapper.clientHeight
        }
      },
      pieSector(){
        const rot = 180 - this.view.yaw
        const fov = this.panoViewer?.getFov()/35
        return `transform: rotate(${rot}deg) scaleX(${fov})`

      },
      fullScreenIcon() {
        return this.isFullScreen ? 'FaCompress' : 'FaExpand'
      }
  },
  methods: {
    GetFullUrl(url) {
      if (!url.includes('http')) {
        url = "//" + url
      }
      return url
    },
    setGyroMode(mode) {
      this.mode = mode;
      console.log('setting ' +mode,this.panoViewer.setGyroMode(mode)) 
    },
    enableSensor(){
        this.setGyroMode('yawPitch')
        this.panoViewer.enableSensor()
        this.sensor = true

        if(this.info.content == 'video') document.getElementById("panoVideo").play()
    },
    toggleFullScreen(){
      this.fullscreen = !this.fullscreen

      this.panoViewer.updateViewportDimensions({
        width: this.fullscreen ? window.innerWidth : this.initSize.width,
        height: this.fullscreen ? window.innerHeight : this.initSize.height
      })
    },
    buttonClass(index) {
      if (this.info.buttons.length == 3 && index == 0) return "is-full-mobile";
      if (this.info.buttons.length == 4) return "is-half-mobile";
      return false;
    },
  },
  mounted() {

    if(this.info.content !== 'video') {

      this.panoViewer = new PanoViewer(
        this.panWrapper,
        {
          image: this.info.image.url,
          projectionType: this.projectionType,
          gyroMode: this.mode,
          width: this.initSize.width,
          height: this.initSize.height,
        }
      )

    } else {

      this.panoViewer = new PanoViewer(
        this.panWrapper,
        {
          video: document.getElementById("panoVideo"),
          projectionType: this.projectionType,
          gyroMode: this.mode,
          autoplay: false,
          width: this.initSize.width,
          height: this.initSize.height,
        }
      )

    }

    this.panoViewer.on("viewChange",(e)=>{

      this.view = {
        yaw: e.yaw
      }
    })

  },
};
</script>

<style lang="scss" scoped>
.link-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  margin: 0 !important;
  padding-inline: .5em;
  flex-wrap: wrap;
  z-index: 22;

  .column {
    padding: 0 .5em .75em;
  }
}
</style>